import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse  } from '@angular/common/http';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NewsfeedService {
  readonly rootUrl = environment.apiEndpoint;
  newsFeedRecord: any;
  constructor(private store: LocalStoreService, private router: Router, private http: HttpClient)
  {}

  addNewsFeed(newNewsFeed: FormData) {
    const requestHeader = new HttpHeaders({ // 'Content-Type':'application/json',
    'Authorization': 'Bearer ' + this.store.getItem('userToken')
    });
    return this.http.post(this.rootUrl + '/api/newsfeed/addnewsfeed',
    newNewsFeed, {headers: requestHeader});
  }

  updateNewsFeed(objNewsFeed: FormData) {
    const requestHeader = new HttpHeaders({ // 'Content-Type':'application/json',
    'Authorization': 'Bearer ' + this.store.getItem('userToken')
    });
    return this.http.put(this.rootUrl + '/api/newsfeed/updatenewsfeed',
    objNewsFeed, {headers: requestHeader});
  }
  getNewsFeedList() {
    const data = JSON.stringify('');
    const requestHeader = new HttpHeaders({'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.store.getItem('userToken')
    });
    return this.http.get(this.rootUrl + '/api/newsfeed/GetNewsFeedList/',
    {headers: requestHeader});
  }

  getAllNewsFeedByCompany(companyId: number, projectId: number) {
    const requestHeader = new HttpHeaders({'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.store.getItem('userToken')
    });
    return this.http.get(this.rootUrl + '/api/newsfeed/GetNewsFeedByCompany/' + companyId.toString() + '/' + projectId.toString() ,
    {headers: requestHeader});
  }

  // getAllNewsFeedByProject(companyId: number, projectId: number) {
  //   const requestHeader = new HttpHeaders({'Content-Type': 'application/json',
  //   'Authorization': 'Bearer ' + this.store.getItem('userToken')
  //   });
  //   return this.http.get(this.rootUrl + '/api/newsfeed/GetNewsFeedListByProject/' + companyId.toString() + '/' + projectId.toString(),
  //   {headers: requestHeader});
  // }


  getNewsFeed(NewsFeedId: number) {
    const requestHeader = new HttpHeaders({'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.store.getItem('userToken')
    });
    return this.http.get(this.rootUrl + '/api/newsfeed/getnewsfeed/' + NewsFeedId.toString(),
    {headers: requestHeader});
  }
}
